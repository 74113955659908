import { lighten } from 'polished'

const colors = {
  primary: '#003bec', // Color for buttons or links
  primaryLight: lighten(0.05, '#003bec'),
  bg: 'white', // Background color
  grey: {
    dark: 'rgba(0, 0, 0, 0.9)',
    default: '#1c1c1c',
    light: 'rgba(0, 0, 0, 0.5)',
    ultraLight: '#8a8a8a',
  },
  white: 'white',
}

const icons = {
  height: '1.2rem',
}

const transitions = {
  normal: '0.5s',
}

const fontSize = {
  small: '0.9rem',
}

const fontFamily = {
  serif: `'Playfair Display', serif`,
  sansSerif: `'Source Sans Pro', sans-serif`,
}

const breakpoints = {
  fullHd: '1900px',
  hd: '1600px',
  tablet: '1200px',
  phone: '600px',
}

const theme = {
  colors,
  icons,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: '1000px',
  baseFontSize: '18px',
}

export default theme
