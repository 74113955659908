/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import theme from '../../config/theme'
import GlobalStyle from './globalStyles'
import Github from '../../assets/icons/github.svg'
import Rss from '../../assets/icons/rss.svg'
import Logo from '../../assets/logo.svg'
import config from '../../config'

export default class MainLayout extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="Blog poświęcony tematyce programowania w takich technologiach jak Javascript, Node.js i ASP.NET Core."
            />
            <meta name="keywords" content="javascript, nodejs, csharp, asp.net, azure" />
            <title>{config.siteTitle}</title>
            <html lang="pl" />
          </Helmet>
          <GlobalStyle />
          <Wrapper>
            <Navbar>
              <div>
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <ul>
                {/* <li>
                  <Search />
                </li> */}
                <li>
                  <a href="https://github.com/mgce">
                    <Github />
                  </a>
                </li>
                <li>
                  <a href="https://contend.pl/feed.xml">
                    <Rss />
                  </a>
                </li>
                {/* <li>
                  <Linkedin />
                </li> */}
              </ul>
            </Navbar>
            {this.props.children}
          </Wrapper>
          <Footer>
            <div>
              <span>2019 Contend</span>
            </div>
          </Footer>
        </>
      </ThemeProvider>
    )
  }
}

const Wrapper = styled.div`
  height: 100%;
  margin: auto;
  max-width: 80rem;
  padding: 0 1.3333rem;

  @media (max-width: ${props => props.theme.breakpoints.hd}) {
    max-width: 72rem;
    min-width: 50rem;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    max-width: 70rem;
    min-width: 0rem;
    margin: 0 auto;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    max-width: 30rem;
    min-width: 0rem;
    margin: 0 auto;
  }
`

const Navbar = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  min-height: 3.722rem;
  width: 100%;
  div > a > svg {
    height: 5rem;
  }
  ul {
    display: flex;
    justify-content: flex-end;

    li {
      display: flex;
      padding: 0 0.4rem;
    }
  }
`

const Footer = styled.footer`
  background-color: ${props => props.theme.colors.grey.default};
  height: 6.61rem;
  margin-top: 7.333rem;
  color: ${props => props.theme.colors.white};

  div {
    display: flex;
    align-items: center;
    margin: auto;
    height: 100%;
    max-width: 73rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0 1rem;
  }
`
